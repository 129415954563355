import { assertNonNull } from 'lib/guards';
import styles from 'lib/TableOfContent.module.css';
import { useLocation } from 'wouter';

function TableOfContent() {
  const [, navigate] = useLocation();

  function handleSectionLinkClick(event: React.MouseEvent<HTMLAnchorElement>) {
    event.preventDefault();
    const to = event.currentTarget.getAttribute('href');
    assertNonNull(to);
    navigate(`${to}`);
    document.querySelector(to)?.scrollIntoView({ behavior: 'smooth' });
  }

  return (
    <div className={styles.list}>
      <div>
        <a
          className={styles.linkHelp}
          href="#q1"
          onClick={handleSectionLinkClick}
        >
          How do I download HOA data?
        </a>
      </div>
      <div>
        <a
          className={styles.linkHelp}
          href="#q2"
          onClick={handleSectionLinkClick}
        >
          What if the downloaded data is corrupt?
        </a>
      </div>
      <div>
        <a
          className={styles.linkHelp}
          href="#q3"
          onClick={handleSectionLinkClick}
        >
          What data format is the HOA data?
        </a>
      </div>
      <div>
        <a
          className={styles.linkHelp}
          href="#q4"
          onClick={handleSectionLinkClick}
        >
          Under what license is HOA data made available?
        </a>
      </div>
      <div>
        <a
          className={styles.linkHelp}
          href="#q5"
          onClick={handleSectionLinkClick}
        >
          How should I cite HOA data?
        </a>
      </div>
      <div>
        <a
          className={styles.linkHelp}
          href="#q6"
          onClick={handleSectionLinkClick}
        >
          How do I search for HOA data?
        </a>
      </div>
      <div>
        <a
          className={styles.linkHelp}
          href="#q7"
          onClick={handleSectionLinkClick}
        >
          Who can I contact if I have questions or problems?
        </a>
      </div>
    </div>
  );
}

export default TableOfContent;
