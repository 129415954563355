import { useMetadataValues } from 'lib/api-hooks';

import PatientCard from './PatientCard';
import styles from './PatientList.module.css';

function PatientList() {
  const patientsUnique = useMetadataValues('SamplePatient_number');

  return (
    <div className={styles.patientContainer}>
      {patientsUnique.map((patient) => {
        return <PatientCard key={patient} patient={patient} />;
      })}
    </div>
  );
}

export default PatientList;
